"use client";
import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {black, Transition, white} from "@/styles/globalStyleVars";
import Title from "@/components/Title";
import Button from "@/components/Button";
import {Img} from "@/components/Img";
import {gsap} from "gsap";
import useContainerOffset from "@/components/useContainerOffset";
import ButtonNormal from "@/components/ButtonNormal";
import reactHtmlParser from "react-html-parser";
import {CURSOR_COLOR, CURSOR_EXCLUSION, CURSOR_HIDE, CURSOR_SHOW, DispatchCursor} from "haspr-cursor";

const MyComponent = ({data, pt, pb}) => {


    const sliderdata = data?.posts?.list;
    const historyData = sliderdata.map((post) => ({
        brand: post?.data?.title || "",
        image: post?.images?.find(i => i?.background === 'on')?.full_path || "",
        logo: post?.images?.find(i => i?.icon === 'on')?.full_path || "",
    }));

    const historydataLink = sliderdata.map((post) => ({
        link: post?.data?.subtitle || "",
    }));


    const sliderRef = useRef();
    const offset = useContainerOffset('.container')
    const [progress, setProgress] = useState(0);
    const [activeTitle, setActiveTitle] = useState('');
    const [activeLink, setActiveLink] = useState('');
    const [activeLogo, setActiveLogo] = useState('');

    const containerRef = useRef(null);

    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    swiperInstance?.autoplay?.start();
                } else {
                    swiperInstance?.autoplay?.stop();
                }
            },
            {threshold: 0.1} // Adjust this threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [swiperInstance]);


    const handleSetTransition = (swiper, speed) => {

        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i].querySelector(".global-image").style.transition = `${speed}ms`;
        }
    };

    const handleProgress = (swiper, event) => {

        var interleaveOffset = 1;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };
    // const handleSetTransition = (swiper, speed) => {
    //     for (let i = 0; i < swiper.slides.length; i++) {
    //         swiper.slides[i].style.transition = `${speed}ms`;
    //         swiper.slides[i].querySelector(".global-image").style.transition = `${speed}ms`;
    //     }
    // };

    const updateProgressBar = (swiper) => {
        const totalSlides = swiper.slides.length;
        const activeIndex = swiper.realIndex;
        const newProgress = ((activeIndex + 1) / totalSlides) * 100;
        setProgress(newProgress);

        const activeSlide = historyData[activeIndex];
        const activeLink = historydataLink[activeIndex];
        setActiveTitle(activeSlide.brand);
        setActiveLogo(activeSlide.logo);
        setActiveLink(activeLink);
    };
    const dispatch = DispatchCursor()
    return (
        <StyledComponent offset={offset} pt={pt} className={'pb-200 pt-200'} onMouseEnter={CURSOR_SHOW}
                         onMouseLeave={CURSOR_HIDE}>
            <div onMouseEnter={() => CURSOR_COLOR('GRAY')} onMouseLeave={() => CURSOR_COLOR('END')}>
                <Container>
                    <div className={'top title'}>
                        <Title text={data?.section_data?.subtitle} margin={'0 0 40px 0'} marginSm={'0 0 20px 0'}/>
                        <div className={'subtitle'}>
                            <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>
                        </div>
                    </div>
                </Container>
                <Container className="slider-container" ref={containerRef}>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        onSwiper={setSwiperInstance}
                        initialSlide={0}
                        loop={true}
                        modules={[Autoplay, Navigation]}
                        speed='1500'
                        // autoplay={{
                        //     delay: 2500, // Delay between transitions (in milliseconds)
                        //     disableOnInteraction: false, // Whether autoplay should stop on user interactions
                        // }}
                        navigation={{
                            prevEl: '.slider_prev',
                            nextEl: '.slider_next',
                        }}
                        // onSlideChange={handleSlideChange}
                        onProgress={handleProgress}
                        onSlideChange={(swiper) => updateProgressBar(swiper)}
                        onSetTransition={handleSetTransition}
                        grabCursor={false}
                        allowTouchMove={false}
                        simulateTouch={false}
                        watchSlidesProgress={true}
                    >
                        {historyData.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="slide-content">
                                    <div className="right">
                                        <div className="img">
                                            <Img src={item.image}/>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className={'box'}>
                        <div className={'brand'}>
                            <p className={'split-left'}>Brand</p>
                            <h4>{activeTitle}</h4>
                        </div>
                        <ButtonNormal src={activeLink?.link} text={'Explore Products'} color={'#534D40'} border={'1px solid #534D40'}
                                      Hoverborder={'1px solid #959185'} hovercolor={'#fff'}/>
                    </div>

                    <div className={'logo'}>
                        <img src={activeLogo} alt={'logo'}/>
                    </div>

                </Container>
                <Container>
                    <div className={'navigation'}>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{width: `${progress}%`}}></div>
                        </div>
                        <div className="top">
                            <div className="top__right">
                                <ul>
                                    <li className={'slider_prev'} onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'MEDIUM')}
                                        onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                        <svg data-magnetism width="12" height="22" viewBox="0 0 12 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 1L0.999999 11L11 21" stroke="#534D40" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </li>
                                    <li className={'slider_next'} onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'MEDIUM')}
                                        onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                        <svg data-magnetism width="12" height="22" viewBox="0 0 12 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 21L11 11L1 1" stroke="#534D40" stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #ffffff;

  .navigation {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 70px;

    .progress-bar-container {
      width: 100%;
      height: 2px;
      background-color: #BDB8B4;
      position: relative;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #534D40;
      transition-delay: 0.2s;
      transition: width 0.7s ease-in-out;
      overflow: visible;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;

    .subtitle {
      display: flex;
      justify-content: flex-end;

      p {
        letter-spacing: -0.32px;
        color: #534D40;
        width: 60%;
        opacity: 0.8;
      }
    }

    h2 {
      letter-spacing: -1.8px;
    }

    &__right {
      ul {
        display: flex;

        li {
          position: relative;
          overflow: hidden;
          display: flex;
          height: 40px;
          width: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;

          svg {
            height: 20px;
            width: 10px;
          }

          //&.slider_prev {
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //
          //  &:hover {
          //    transform: translateX(-10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
          //
          //&.slider_next {
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //
          //  &:hover {
          //    transform: translateX(10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
        }
      }
    }
  }

  .slide-content {
    .right {
      .img {
        position: relative;
        padding-top: calc(600 / 1170 * 100%);
        overflow: hidden;
      }
    }
  }

  .slider-container {
    position: relative;

    .box {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 15px;
      padding: 40px 0 0 0;
      background-color: ${white};
      min-width: 370px;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.42px;
        color: ${black};
      }

      .brand {
        p {
          margin-bottom: 15px;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          color: #959185;
        }

        h4 {
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: -0.96px;
          color: ${black};
        }

        margin-bottom: 40px;
      }
    }

    .logo {
      z-index: 10;
      position: absolute;
      bottom: 20px;
      right: 30px;
      height: 120px;
      width: 120px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    .slider-container {
      padding: 0;

      .box {
        left: 0;
        padding: 40px 20px 0;
        margin: 0 15px;
        min-width: calc(100% - 30px);
      }

      .logo {
        z-index: 10;
        position: absolute;
        top: 20px;
        right: 20px;
        bottom: 20px;
        height: 80px;
        width: 80px;
      }
    }

    .top {
      flex-direction: column;

      &.title {
        margin-bottom: 30px;
      }

      .subtitle {
        justify-content: flex-start;

        p {
          width: 100%;
        }
      }

      &__right {
        ul {
          .slider_prev {
            &:hover {
              transform: translateX(0) !important;
            }
          }

          .slider_next {
            &:hover {
              transform: translateX(0) !important;
            }
          }
        }
      }
    }

    .logo {
      height: 120px;
      width: 120px;
      bottom: unset;
      top: 20px;
      right: 20px;
    }

    .slide-content {
      .right {
        .img {
          position: relative;
          padding-top: calc(500 / 375 * 100%);
        }
      }

      .bottom {
        h5 {
          letter-spacing: -0.6px;
        }
      }
    }

    .navigation {
      margin-top: 30px;
      gap: 20px;
    }

    .swiper-slide {
      width: 100% !important;
    }
  }

  .swiper-slide {
    overflow: hidden;

    .global-image {
      overflow: hidden;

      img {
        top: unset;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  @media (min-width: 767px) and (max-width: 991px) {
    .slider-container {

      .box {
        left: 0;
        padding: 30px 15px 0;
        margin: 0 15px;
        min-width: 300px;

        .brand {
          margin-bottom: 10px;
        }
      }
    }
  }


`;

export default MyComponent;
