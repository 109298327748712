import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/CollectionSlider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/MasonryGallery.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/MediaNews.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/TrendingItem.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/shuvoalamin/Desktop/OFFICE/Projects/REACT/BHL/components/home/VirtualShowroom.jsx");
