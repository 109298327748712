"use client";
import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {black, Transition} from "@/styles/globalStyleVars";
import Title from "@/components/Title";
import useContainerOffset from "@/components/useContainerOffset";
import Button from "@/components/Button";
import ButtonNormal from "@/components/ButtonNormal";
import Link from "next/link";
import {CURSOR_COLOR, CURSOR_HIDE, CURSOR_SHOW, CURSOR_TEXT, DispatchCursor} from "haspr-cursor";


const MyComponent = ({data, pt, list}) => {

    const sliderRef = useRef();
    const offset = useContainerOffset('.container')
    const [progress, setProgress] = useState(0);
    const [activeTitle, setActiveTitle] = useState('');
    const [activeYear, setActiveYear] = useState('');

    const containerRef = useRef(null);

    const [swiperInstance, setSwiperInstance] = useState(null);


    const historyData = list?.list?.map((i) => ({
        title: i?.page_data?.title || '',
        slug: i?.page_data?.slug || '',
        image: i?.images?.list?.[0]?.full_path,
        subtitle: i?.page_data?.subtitle || '',
    }));

    const updateProgressBar = (swiper) => {
        const totalSlides = swiper.slides.length;
        const activeIndex = swiper.realIndex;
        const newProgress = ((activeIndex + 1) / totalSlides) * 100;
        setProgress(newProgress);

        const activeSlide = historyData[activeIndex];
        setActiveTitle(activeSlide.title);
        setActiveYear(activeSlide.year);
    };
    const dispatch = DispatchCursor()
    return (
        <StyledComponent offset={offset} pt={pt} className={'pt-200 pb-200'} onMouseEnter={CURSOR_SHOW}
                         onMouseLeave={CURSOR_HIDE}>
            <div onMouseEnter={() => CURSOR_COLOR('BLACK')} onMouseLeave={() => CURSOR_COLOR('END')}>
                <Container>
                    <div className={'top title'}>
                        <Title text={data?.section_data?.subtitle} margin={'0 0 40px 0'} marginSm={'0 0 20px 0'}/>
                        <ButtonNormal src={'/news-media'} text={'Explore Media & News'} color={'#534D40'}
                                      border={'1px solid #534D40'}
                                      Hoverborder={'1px solid #959185'} hovercolor={'#fff'}/>
                    </div>
                </Container>
                <Container fluid className="slider-container" ref={containerRef}>
                    <Swiper
                        loop={true}
                        onSwiper={setSwiperInstance}
                        slidesPerView={'auto'}
                        spaceBetween={30}
                        speed={1500}
                        slidesPerGroup={1}
                        modules={[Autoplay, Pagination, Navigation, Mousewheel]}
                        navigation={{
                            prevEl: '.slider_prev',
                            nextEl: '.slider_next',
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        onSlideChange={(swiper) => updateProgressBar(swiper)}
                        ref={sliderRef}
                        className="mySwiper"
                    >
                        {historyData.map((item, index) => (
                            <SwiperSlide key={index} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                <Link href={`/news-media/${item?.slug}`} key={index} legacyBehavior>
                                    <div className="slide-content">
                                        <div className="right">
                                            <div className="img">
                                                <img src={item.image} alt={item?.title}/>
                                            </div>
                                        </div>
                                        <div className={'bottom'}>
                                            <h6>{item?.title}</h6>
                                            <div className={'code'}>
                                                <p>{item?.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </Container>
                <Container>
                    <div className={'navigation'}>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{width: `${progress}%`}}></div>
                        </div>
                        <div className="top">
                            <div className="top__right">
                                <ul>
                                    <li className={'slider_prev'}>
                                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 1L0.999999 11L11 21" stroke="#534D40" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </li>
                                    <li className={'slider_next'}>
                                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 21L11 11L1 1" stroke="#534D40" stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #ffffff;

  .slider-container {
    padding: 0;
  }

  .navigation {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 70px;

    .progress-bar-container {
      width: 100%;
      height: 2px;
      background-color: #BDB8B4;
      position: relative;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #534D40;
      transition-delay: 0.2s;
      transition: width 0.7s ease-in-out;
      overflow: visible;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;

    .subtitle {
      display: flex;
      justify-content: flex-end;

      p {
        letter-spacing: -0.32px;
        color: #534D40;
        width: 60%;
        opacity: 0.8;
      }
    }

    h2 {
      letter-spacing: -1.8px;
    }

    &__right {
      ul {
        display: flex;

        li {
          position: relative;
          overflow: hidden;
          display: flex;
          height: 40px;
          width: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;

          svg {
            height: 20px;
            width: 10px;
          }

          //&.slider_prev{
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //  &:hover {
          //    transform: translateX(-10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
          //&.slider_next{
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //  &:hover {
          //    transform: translateX(10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
        }
      }
    }
  }

  .slide-content {
    .bottom {
      margin-top: 30px;

      .code {
        padding-top: 15px;

        p {
          color: #534D40;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        div {
          height: 18px;
          width: 18px;
          background: #AF966D;
          border-radius: 50px;
        }
      }

      h6 {
        color: ${black};
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.54px;
      }
    }

    .right {
      overflow: hidden;

      img {
        transform: scale(1);
        transition: 0.5s ${Transition} transform;
        overflow: hidden;

        &:hover {
          transform: scale(1.1);
          transition: 0.5s ${Transition} transform;
        }
      }
    }
  }

  .swiper {
    margin-left: ${props => props.offset + 15 + 'px'};
    padding-right: ${props => props.offset + 15 + 'px'};
  }

  @media (max-width: 767px) {
    .swiper {
      padding-right: 70px;
      margin-left: 0;

      .swiper-wrapper {
        margin-left: 15px;
      }
    }

    .top {
      flex-direction: column;

      &.title {
        margin-bottom: 30px;
      }

      .subtitle {
        justify-content: flex-start;

        p {
          width: 100%;
        }
      }

      &__right {
        ul {
          .slider_prev {
            &:hover {
              transform: translateX(0) !important;
            }
          }

          .slider_next {
            &:hover {
              transform: translateX(0) !important;
            }
          }
        }
      }
    }

    .slide-content {
      .bottom {
        h5 {
          letter-spacing: -0.6px;
        }
      }
    }

    .navigation {
      margin-top: 30px;
      gap: 20px;
    }
  }



`;

export default MyComponent;
