'use client'
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Link from 'next/link'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {Transition, white} from "@/styles/globalStyleVars";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css"
import reactHtmlParser from "react-html-parser";
import {CURSOR_COLOR, CURSOR_HIDE, CURSOR_SHOW, CURSOR_TEXT, DispatchCursor} from "haspr-cursor";

const MyComponent = ({data}) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) return null;


    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} alt={'images'}/>
        </LightgalleryItem>

    );
    const dispatch = DispatchCursor()
    return (
        <StyledComponent className={'pt-200 pb-200'} onMouseEnter={CURSOR_SHOW}
                         onMouseLeave={CURSOR_HIDE}>
            <Container onMouseEnter={() => CURSOR_COLOR('BLACK')} onMouseLeave={() => CURSOR_COLOR('END')}>
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: false,
                            share: false
                        }
                    }
                >
                    <Row>
                        <Col>
                            <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 750: 2, 900: 4}}>
                                <Masonry gutter={'30px'} children={'2'} className={'masonry'} >
                                    <div className={'text'}>
                                        <h4>{reactHtmlParser(data?.section_data?.subtitle)}</h4>
                                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>
                                    </div>
                                    <div className={'wrapper'} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                        <PhotoItem image={data?.images?.list?.find(i=>i?.short_desc === '1')?.full_path}/>
                                        <h5>{reactHtmlParser(data?.images?.list?.find(i=>i?.short_desc === '1')?.short_title)}</h5>
                                    </div>
                                    <div className={'wrapper'} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                        <PhotoItem image={data?.images?.list?.find(i=>i?.short_desc === '2')?.full_path}/>
                                        <h5>{reactHtmlParser(data?.images?.list?.find(i=>i?.short_desc === '2')?.short_title)}</h5>
                                    </div>
                                    <div className={'wrapper'} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                        <PhotoItem image={data?.images?.list?.find(i=>i?.short_desc === '3')?.full_path}/>
                                        <h5>{reactHtmlParser(data?.images?.list?.find(i=>i?.short_desc === '3')?.short_title)}</h5>
                                    </div>
                                    <div className={'wrapper'} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                        <PhotoItem image={data?.images?.list?.find(i=>i?.short_desc === '4')?.full_path}/>
                                        <h5>{reactHtmlParser(data?.images?.list?.find(i=>i?.short_desc === '4')?.short_title)}</h5>
                                    </div>
                                    <div/>
                                    <div className={'wrapper'} onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'BLACK')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                        <PhotoItem image={data?.images?.list?.find(i=>i?.short_desc === '5')?.full_path}/>
                                        <h5>{reactHtmlParser(data?.images?.list?.find(i=>i?.short_desc === '5')?.short_title)}</h5>
                                    </div>

                                </Masonry>
                            </ResponsiveMasonry>
                        </Col>
                    </Row>
                </LightgalleryProvider>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  background: #959185;

  .text {
    h4 {
      color: ${white};
      letter-spacing: -0.96px;
      margin-bottom: 20px;
    }

    p {
      color: ${white};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  .masonry {
   @media(min-width: 992px){
     > div:first-of-type {
       justify-content: space-between !important;
       margin-bottom: 30px;
       gap:unset!important;
     }
   }

    .wrapper {
      position: relative;
      overflow: hidden;

      img {
        overflow: hidden;
        transform: scale(1);
        transition: 0.5s ease transform;

        &:hover {
          transform: scale(1.1);
          transition: 0.5s ease transform;
        }
      }

      h5 {
        position: absolute;
        left: 30px;
        bottom: 30px;
        color: white;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        letter-spacing: -0.72px;
        z-index: 1;
      }
    }
  }


  @media (max-width: 767px) {
    .masonry {
      gap: 15px !important;
      .wrapper{
        h5{
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: -0.6px;
          left: 15px;
          bottom: 15px;
        }
      }
      div {
        gap: 15px !important;
      }
    }
  }

`;

export default MyComponent;
